<template>
  <div />
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'Logout',
  computed: {
    ...mapActions('auth', ['logout'])
  },
  created() {
    this.logout()
    this.$router.push('/login')
  }
}
</script>

<style lang="scss" scoped>

</style>