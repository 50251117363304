<template>
  <validation-observer
    ref="formValidation"
    v-slot="{ handleSubmit }"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit(onSubmit)"
    >
      <BaseFormRadio
        v-model="mfaType"
        label="Choose MFA Type"
        :options="options"
        stacked
      />

      <div class="text-center mt-3">
        <b-alert
          :show="!!loginError.errorMessage"
          variant="danger"
        >
          {{ loginError.errorMessage }}
        </b-alert>

        <BaseButtonLoader
          button-class="custom-button--wide custom-button--white-hover-grad pulse"
          type="submit"
          :loading="isLoading"
        >
          Next
        </BaseButtonLoader>

        <BaseButtonLoader
          button-class="custom-button--wide custom-button--white-hover-grad pulse"
          type="button"
          @click="resetLoginStageState"
        >
          Back
        </BaseButtonLoader>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { MFA_STAGES } from '@/store/modules/auth.store'

export default {
  name: 'LoginChooseMFAType',

  data() {
    return {
      mfaType: MFA_STAGES.SMS_MFA,
      isLoading: false,
      options: [
        {
          text: 'SMS',
          value: MFA_STAGES.SMS_MFA
        },
        {
          text: 'Authenticator App',
          value: MFA_STAGES.SOFTWARE_TOKEN_MFA
        }
      ]
    }
  },

  computed: {
    ...mapState('auth', [
      'loginError'
    ])
  },

  methods: {
    ...mapActions('auth', [
      'submitMFATypeSelected',
      'resetLoginStageState'
    ]),

    async onSubmit() {
      this.isLoading = true

      try {
        await this.submitMFATypeSelected(this.mfaType)
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>

<style>

</style>