<template>
  <div>
    <h5 class="text-center mt-3">
      Logged in successfully, redirecting you to your dashboard
    </h5>
  </div>
</template>

<script>
export default {
  name: 'LoginSuccess'
}
</script>

<style>

</style>