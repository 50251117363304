<template>
  <div>
    <div class="mb-3">
      Please set a permanent password, temporary password is valid only for the first time you login
    </div>

    <ChangePassword
      :is-loading="isLoading"
      :error-message="loginError.errorMessage"
      @change-password="onSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import ChangePassword from '@/components/change-password/ChangePassword.vue'

export default {
  name: 'LoginEnterPermanentPassword',

  components: {
    ChangePassword,
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState('auth', [
      'loginError'
    ]),

    ...mapGetters('auth', [
      'isAuthenticated'
    ])
  },

  methods: {
    ...mapActions('auth', [
      'setPermanentPassword'
    ]),

    async onSubmit(newPassword) {
      try {
        this.isLoading = true

        await this.setPermanentPassword(newPassword)

        if (this.isAuthenticated) {
          if (this.redirectToAfterLoginPath) {
            this.$router.push(this.redirectToAfterLoginPath)
          } else {
            this.$router.push({
              name: 'Dashboard'
            })
          }
        }
      } catch (error) {
        // Do nothing here, since we show error under the form
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>

<style>

</style>