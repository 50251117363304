<template>
  <div>
    <validation-observer
      ref="formValidation"
      v-slot="{ handleSubmit, invalid, untouched }"
    >
      <b-form
        novalidate
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <BaseFormInput
          id="new-password"
          v-model="newPassword"
          name="newPassword"
          label="New Password"
          placeholder="Enter your new password"
          type="password"
          vid="new-password"
          :rules="{ regex: PASSWORD_REGEX }"
          required
        />

        <BaseFormInput
          id="confirm-password"
          v-model="confirmPassword"
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Re-enter password"
          type="password"
          rules="confirmed:new-password"
          required
        />

        <div class="text-center mt-3">
          <b-alert
            data-cy="alert-error"
            :show="!!errorMessage"
            variant="danger"
          >
            {{ errorMessage }}
          </b-alert>

          <BaseButtonLoader
            button-class="custom-button--white-hover-grad pulse"
            class="mx-2"
            type="submit"
            data-cy="button-login"
            :disabled="untouched || invalid"
            :loading="isLoading"
          >
            Change Password
          </BaseButtonLoader>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { PASSWORD_REGEX } from '@/store/modules/auth.store'

export default {
  name: 'ChangePassword',

  props: {
    errorMessage: {
      type: String,
      default: ''
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      PASSWORD_REGEX: Object.freeze(PASSWORD_REGEX)
    }
  },

  methods: {
    onSubmit() {
      this.$emit('change-password', this.newPassword)
    }
  },
}
</script>

<style>

</style>