<template>
  <b-container class="mx-sm-auto login__wrapper">
    <div class="w-50 mx-auto px-3 mb-2">
      <RingbaXLogoDark />
    </div>

    <transition
      name="component-fade"
      mode="out-in"
    >
      <keep-alive>
        <component
          :is="loginStepComponent"
          @submit="onSubmit"
        />
      </keep-alive>
    </transition>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import RingbaXLogoDark from '@/assets/img/ringbax-logo-dark-theme.svg'
import LoginEmail from '@/components/login/LoginEmail.vue'
import LoginChooseMFAType from '@/components/login/LoginChooseMFAType.vue'
import LoginEnterMFACode from '@/components/login/LoginEnterMFACode.vue'
import LoginEnterPermanentPassword from '@/components/login/LoginEnterPermanentPassword.vue'
import { MFA_STAGES } from '@/store/modules/auth.store'
import LoginSuccess from '@/components/login/LoginSuccess.vue'

const stageComponents = {
  [MFA_STAGES.ENTER_USER_CREDS]: LoginEmail,
  [MFA_STAGES.SELECT_MFA_TYPE]: LoginChooseMFAType,
  [MFA_STAGES.SMS_MFA]: LoginEnterMFACode,
  [MFA_STAGES.SOFTWARE_TOKEN_MFA]: LoginEnterMFACode,
  [MFA_STAGES.NEW_PASSWORD_REQUIRED]: LoginEnterPermanentPassword,
  [MFA_STAGES.LOGIN_SUCCESS]: LoginSuccess
}

export default {
  name: 'Login',

  title: 'Ringba X',

  components: {
    RingbaXLogoDark,
    LoginEmail,
    LoginChooseMFAType,
    LoginEnterMFACode
  },

  computed: {
    ...mapGetters('auth', [
      'currentAuthChallenge'
    ]),

    loginStepComponent() {
      return stageComponents[this.currentAuthChallenge]
    },
  },

  created () {
    this.resetLoginStageState()
    this.logout()
  },

  methods: {
    ...mapActions('auth', [
      'resetLoginStageState',
      'logout'
    ]),
    onSubmit() {},
  },
}
</script>

<style lang="scss" scoped>
.login__wrapper {
  min-height: 400px;
}

@include media-breakpoint-up(sm) {
  .login__wrapper {
    width: 467px;
  }
}
</style>